import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Prismic from "prismic-javascript"
import { useQuery } from "react-query"
import DynamicSpeakerList from "../components/dynamicSpeakerList"
import styled from "styled-components"
const apiEndpoint = "https://globart-academy.prismic.io/api/v2"
const apiToken =
  "MC5YeW1JSUJFQUFOOWdGZGIw.77-9KQQ9dkzvv73vv71Q77-977-9Du-_vTbvv73vv71j77-977-977-977-977-977-9Sn3vv73vv73vv73vv70L77-9Uw"
const PAGE_SIZE = 100

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  @media only screen and (max-width: 1100px) {
    margin-top: 5rem;
  }
  @media only screen and (max-width: 800px) {
    margin-top: 0;
    justify-content: flex-end;
  }
`
const Search = styled.input`
  margin-right: 1rem;
  padding: 0.3rem;
  border: 1px solid black;
  text-align: left;
  width: calc(70% - 2rem);
  :focus {
    outline: none;
    border-color: rgb(131, 180, 19);
  }
  @media only screen and (max-width: 800px) {
    width: calc(100% - 2rem);
  }
`

const fetchSpeakers = async () => {
  const api = await Prismic.getApi(apiEndpoint, { accessToken: apiToken })

  const getSpeakers = async (page = 1) => {
    const result = await api.query(
      Prismic.Predicates.at("document.type", "speaker"),
      {
        pageSize: PAGE_SIZE,
        orderings: "[my.speaker.title]",
        page: page,
      }
    )

    if (result.total_pages !== page) {
      const nextResults = await getSpeakers(page + 1)
      return [...result.results, ...nextResults]
    }

    return result.results
  }

  return getSpeakers()
}

function Speakers(props) {
  const [speakers, setSpeakers] = useState([])
  const [filter, setFilter] = useState("")

  useEffect(() => {
    fetchSpeakers().then(s => setSpeakers(s))
  }, [])

  const filteredDataSpeaker = speakers.filter(item => {
    return item.data.title[0].text.toLowerCase().indexOf(filter) !== -1
  })
  const filter2019 = filteredDataSpeaker.filter(item => {
    return item.data.is_2019 === true
  })
  const filter2020 = filteredDataSpeaker.filter(item => {
    return item.data.is_2020 !== true
  })
  const data = filter2020.concat(filter2019)

  return (
    <>
      <Layout location={props.path}>
        <SearchContainer>
          <Search
            value={filter}
            onChange={e => setFilter(e.target.value.toLowerCase())}
            placeholder="Suche.. &#x1F50D;"
          />
        </SearchContainer>
        <DynamicSpeakerList heading="Referentinnen" speakers={data} />
      </Layout>
    </>
  )
}

export default Speakers
